import "./NewFooter.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { PageTypeEnum } from "../../../Model/Enums/PageTypeEnums";
const NewFooter = () => {
  return (
    <div>
      <footer className="bg-white paddingTop">
        <div className="container">
          <div className="bottom_footerpart pb-3 pb-md-4 pb-lg-5">
            <div className="row gy-4">
              <div className="col-md-6 col-lg-3">
                <h5 className="fw-bold black_clr">Do You Need Help ?</h5>
             
                <HashLink
                  className="d-inline-flex align-items-center anchor_link text-dark"
                  to="#"
                >
                  <i className="bi bi-telephone-fill me-2"></i> +91 72176 47998
                </HashLink>
                <br />
                <Link
                  className="d-inline-flex align-items-center anchor_link text-dark mt-2"
                  to=""
                  onClick={() =>
                    (window.location.href = "mailto:b2b@nutritap.in")
                  }
                >
                  <i className="bi bi-envelope-fill me-2"></i> b2b@nutritap.in
                </Link>
              </div>

              <div className="col-md-6 col-lg-3 ps-lg-5">
                <h5 className="fw-bold black_clr mb-3">Quick Links</h5>
                <ul className="footer_links">
                  <li>
                    <HashLink to={`${PageTypeEnum.HOME}#top`}>Home</HashLink>
                  </li>
                  <li>
                    <HashLink to={"/howitworks#top"}>How it works</HashLink>
                  </li>
                  <li>
                    <HashLink to={`/${PageTypeEnum.CATEGORY}#top`}>
                      Shop All
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to={"/aboutUs#top"}>About us</HashLink>
                  </li>
                  <li>
                    <HashLink to="/newMyAccount#top">Account</HashLink>
                  </li>
                </ul>
              </div>

              <div className="col-md-6 col-lg-3 ps-lg-4">
                <h5 className="fw-bold black_clr mb-3">Let us help you</h5>
                <ul className="footer_links">
                  <li>
                    <HashLink to={"/terms&Conditions#top"}>
                      Terms Conditions
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to={"/refundPolicy#top"}>
                      Refund & Cancellation Policy
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to={"/privacyPolicy#top"}>
                      Privacy Policy
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to={"/faq#top"}>
                      FAQ
                    </HashLink>
                  </li>
                </ul>
              </div>

              <div className="col-md-6 col-lg-3 ps-lg-4">
                <h5 className="fw-bold black_clr mb-3">
                  Follow us on social media
                </h5>
                <ul className="footer_links d-flex align-items-center">
                  <li>
                    <Link
                      to="https://www.facebook.com/NutriTap/"
                      target="_blank"
                    >
                      <i className="bi bi-facebook fs-4 me-4"></i>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="https://twitter.com/" target="_blank">
                      <i className="bi bi-twitter-x fs-4 me-4"></i>
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="https://www.instagram.com/nutritap_official/"
                      target="_blank"
                    >
                      <i className="bi bi-instagram fs-4 me-4"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/nutritap/"
                      target="_blank"
                    >
                      <i className="bi bi-linkedin fs-4 me-4"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="copyright_text py-3">
            <p className="mb-0 fs-14">
              Copyright 2025 © NutriTap. All right reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default NewFooter;
