import React, { useEffect, useState } from "react";
import "./NewOrder.css";
import { Subscription } from "rxjs";
import {
  ApiCallResponse,
  PageEventTypeEnum,
} from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { OrderDisplayDto } from "../../../Model/OrderModel";
import { OrderService } from "../../../Services/OrderService";
import { apiLoadingSubject, ApprovalPendingOrderStore } from "../../../Store";
import { DateUtil } from "../../../Services/DateService/dateUtil";
import noDataFound from "../../../Components/Images/NoData.jpeg";
import { CheckService } from "../../../Services/checkValidationService";
import { showErrorToast } from "../../../utils/Toastify/ToastifyHandler";
import { useNavigate } from "react-router";
import TableLoading from "../../LoadingComponent/TableLoading";
import { createOrderStatusElement } from "../../../utils/StatusBox";

const NewApprovePendingOrder = () => {
  const [pendingOrders, setPendingOrders] = useState<OrderDisplayDto[]>(
    new Array<OrderDisplayDto>()
  );
  const [isApprovalLoading, setApprovedLoading] = React.useState<boolean>(true);

  const navigate = useNavigate();

  const date = new DateUtil();

  const order = new OrderService();

  const getApprovalPendingOrders = () => {
    order.getAllApprovalPending();
  };
  const acceptOrderHandler = (orderId: number) => {
    order.approveOrder(orderId);
  };
  const rejectOrderHandler = (orderId: number) => {
    order.deleteOrder(orderId);
  };

  useEffect(() => {
    let orderSubscriber: Subscription;

    getApprovalPendingOrders();

    orderSubscriber = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.PENDING_ORDER === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setApprovedLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              setPendingOrders(ApprovalPendingOrderStore.getOrder());
              setApprovedLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorToast(response.data);
              setApprovedLoading(false);
              break;
            case ApiCallState.RELOAD:
              getApprovalPendingOrders();
          }
        }
      }
    );

    return () => {
      if (orderSubscriber) {
        orderSubscriber.unsubscribe();
      }
    };
  }, []);
  const navigateToOrderDetail = (orderId: number) => {
    navigate(`/OrderDetails/${orderId}`);
  };

  return (
    <>
      <div className="table-responsive mt-4 col-7 table-fixed scrollbar table-wrap">
        {isApprovalLoading ? (
          <TableLoading />
        ) : pendingOrders.length > 0 ? (
          <table className="default_table table table-hover">
            <thead>
              <tr className="green_clr">
                <th scope="col"> Order ID</th>
                <th scope="col">Ordered Date</th>
                <th scope="col">Expected Delivery Date</th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col">Billing Amount</th>
                <th scope="col">Payment Status</th>
                <th scope="col">Order Details</th>
                {CheckService.isAdmin() && <th scope="col">Action</th>}
              </tr>
            </thead>
            <tbody>
              {pendingOrders.map((item, index) => {
                return (
                  <tr>
                    <td className="fs-14">{item.orderIdentifier}</td>
                    <td className="fs-14">
                      {date.getDateFromEpoch(item.createdOn)}
                    </td>
                    <td className="fs-14">
                      {date.calculateExpectedDeliveryDate(item.createdOn)}
                    </td>
                    <td className="fs-14">
                      <p className="fs-14 fw-500 mb-0">{item.createdByEmail}</p>
                    </td>
                    <td className="fs-14"> {createOrderStatusElement(item.orderStatus)}</td>
                    <td className="fs-14 ">
                      {`₹ ${(+item.billingAmount.toFixed(2)).toLocaleString(
                        "en-IN"
                      )}`}
                    </td>
                    <td className="table-hover">
                      <span
                        role="button"
                        onClick={() => navigateToOrderDetail(item.id)}
                        className="text-primary"
                      >
                        View Order
                      </span>
                    </td>
                    <td className="fs-14">{item.paymentStatus}</td>
                    {CheckService.isAdmin() && (
                      <td className="d-flex flex-column  ">
                        <button
                          className="btn btn-success p-1 m-1 rounded"
                          onClick={() => acceptOrderHandler(item.id)}
                        >
                          Approve
                        </button>
                        <button
                          className=" p-1 btn btn-danger rounded m-1"
                          onClick={() => rejectOrderHandler(item.id)}
                        >
                          Reject
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="image-pos">
            <img className="no-data-found" src={noDataFound} alt="" />
          </div>
        )}
      </div>
    </>
  );
};

export default NewApprovePendingOrder;
